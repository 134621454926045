import React from "react"
import styled from "@emotion/styled"

const Flex = styled.div`
  width: 70%;
  margin-left: 15%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    width: 80%;
    margin-left: 10%;
  }
`

const Title = styled.h4`
  margin-top: 20px;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 26px;
    margin-top: 10px;
  }
`
const List = styled.ul`
  list-style-type: circle;
`

const Item = styled.li`
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const Skills = () => (
  <Flex>
    <Title>My Skills:</Title>
    <List>
      <Item>React</Item>
      <Item>Javascript ES6</Item>
      <Item>HTML & CSS</Item>
      <Item>Git</Item>
      <Item>NodeJS</Item>
      <Item>Styled-Components</Item>
      <Item>Gatsby</Item>
    </List>
    <Title>Currently Learning:</Title>
    <List>
      <Item>MongoDB</Item>
      <Item>SVG animations</Item>
      <Item>NextJS</Item>
      <Item>Typescript</Item>
      <Item>graphQL</Item>
    </List>
    <Title>Curious About:</Title>
    <List>
      <Item>IoT</Item>
      <Item>Open smart home APIs</Item>
      <Item>How to keep coffee warm</Item>
    </List>
  </Flex>
)

export default Skills
