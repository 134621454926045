import React from "react"
import styled from "@emotion/styled"
import WindowImg from "../images/window.svg"
import Shades from "../images/shades.svg"
import { css } from "@emotion/core"

const WindowDiv = styled.div`
  flex: 0 0 50%;
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  @media (max-width: 1200px) {
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
  }
  @media (max-width: 992px) {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    flex: 0 0 25%;
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    flex: 0 0 33%;
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
  @media (max-width: 480px) {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    flex: 0 0 33%;
  }
`
function bgSelect(title) {
  if (title === 1) {
    return "#5133A6"
  } else if (title === 2) {
    return "#004F87"
  } else if (title === 3) {
    return "#FFB703"
  } else if (title === 4) {
    return "#362B3B"
  } else if (title === 5) {
    return "#282b47"
  }
}
const Div = styled.div`
  position: absolute;
  margin-left: 38px;
  margin-top: 39px;
  width: 372px;
  height: 352px;
  background: #5a5a5a;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
  transition: background ease 1s;
  -webkit-transition: background ease 1s;
`
const StyledWindow = styled(WindowImg)`
  width: 448px;
  height: 426px;
`
const StyledShades = styled(Shades)`
  height: 330px;
  width: 360px;
  z-index: 3;
  margin-top: 38px;
  margin-left: 47px;
  position: absolute;
  pointer-events: none;
  @-webkit-keyframes dash-back {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0.3);
    }
  }
  @keyframes dash-back {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0.3);
    }
  }
  @-webkit-keyframes dash-forward {
    from {
      transform: scaleY(0.3);
    }
    to {
      transform: scaleY(1);
    }
  }
  @keyframes dash-forward {
    from {
      transform: scaleY(0.3);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-webkit-keyframes handle-back {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-222.6px);
    }
  }
  @keyframes handle-back {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-222.6px);
    }
  }
  @-webkit-keyframes handle-forward {
    from {
      transform: translateY(-222.6px);
    }
    to {
      transform: translateY(0px);
    }
  }
  @keyframes handle-forward {
    from {
      transform: translateY(-222.6px);
    }
    to {
      transform: translateY(0px);
    }
  }
  .shades {
    transform-box: fill-box;
    transform-origin: center top;
    -webkit-transform-box: fill-box;
    -webkit-transform-origin: center top;
    animation: dash-forward 1s ease;
  }
  .handle {
    transform-box: fill-box;
    transform-origin: center top;
    -webkit-transform-box: fill-box;
    -webkit-transform-origin: center top;
    animation: handle-forward 1s ease;
  }
  ${WindowDiv}:hover & {
    .shades {
      animation: dash-back 1s ease;
      transform: scaleY(0.3);
      -webkit-transform: scaleY(0.3);
    }
    .handle {
      animation: handle-back 1s ease;
      transform: translateY(-222.6px);
      -webkit-transform: translateY(-222.6px);
    }
  }
`

const Window = ({ title, children }) => {
  function titleSelect(title) {
    if (title === 1) {
      return (
        <Flex>
          <Title>Games with Hue</Title>
        </Flex>
      )
    } else if (title === 2) {
      return (
        <Flex>
          <Title>Stasis Rehab</Title>
        </Flex>
      )
    } else if (title === 3) {
      return (
        <Flex>
          <Title>Iconoclasm</Title>
        </Flex>
      )
    } else if (title === 4) {
      return (
        <Flex>
          <Title>VTTS Financing</Title>
        </Flex>
      )
    } else if (title === 5) {
      return (
        <Flex>
          <Title>Coming Soon!</Title>
        </Flex>
      )
    }
  }

  return (
    <WindowDiv>
      <>{titleSelect(title)}</>
      <StyledShades />
      <Div
        css={css`
          ${WindowDiv}:hover & {
            background: ${bgSelect(title)};
          }
        `}
      >
        {children}
      </Div>
      <StyledWindow />
    </WindowDiv>
  )
}
export default Window

const Title = styled.h2`
  flex: 0 2 auto;
  font-size: 28px;
  color: #282b47;
  margin-right: 0px;
  transform-box: fill-box;
  transform-origin: center top;
  animation: handle-forward 1s ease;
  @-webkit-keyframes handle-back {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-222.6px);
    }
  }
  @keyframes handle-back {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-222.6px);
    }
  }
  @-webkit-keyframes handle-forward {
    from {
      transform: translateY(-222.6px);
    }
    to {
      transform: translateY(0px);
    }
  }
  @keyframes handle-forward {
    from {
      transform: translateY(-222.6px);
    }
    to {
      transform: translateY(0px);
    }
  }
  ${WindowDiv}:hover & {
    animation: handle-back 1s ease;
    transform: translateY(-222.6px);
    -webkit-transform: translateY(-222.6px);
  }
`
const Flex = styled.div`
  width: 440px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 100px;
  z-index: 6;
  pointer-events: none;
  @media (max-width: 768px) {
    bottom: 120px;
  }
`
