import React from "react"
import styled from "@emotion/styled"
import Window from "../components/window"
import Main from "../images/windowmain.svg"
import Plants from "../components/plants"
import Door from "../images/door.svg"
import About from "../components/aboutme"
import Skills from "../components/skills"
import Preview from "../projects/preview"
import Me from "../images/me.svg"

const BuildingDiv = styled.div`
  position: relative;
  width: 85%;
  height: 2866px;
  margin-left: 15%;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 95%;
    margin-left: 5%;
    padding-left: 5%;
  }
  @media (max-width: 992px) {
    width: 80%;
    height: 3152px;
    margin-top: 267px;
    padding-left: 15%;
  }
  @media (max-width: 768px) {
    margin-left: 0%;
    width: 85%;
    height: 3152px;
    margin-top: 267px;
    padding-left: 20%;
  }
  @media (max-width: 480px) {
    width: 85%;
    height: 3152px;
    margin-top: 267px;
  }
`
const Flex = styled.div`
  width: 100%;
  padding-top: 160px;
  height: 1950px;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 992px) {
    padding-top: 200px;
    height: 1850px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    height: 1550px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  @media (max-width: 480px) {
    padding-top: 250px;
    height: 1250px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`
const WindowDiv = styled.div`
  flex: 0 0 50%;
  transform: scale(0.8);
  @media (max-width: 1200px) {
    transform: scale(0.75);
  }
  @media (max-width: 992px) {
    transform: scale(0.7);
    flex: 0 0 25%;
    margin-top: -60px;
  }
  @media (max-width: 768px) {
    transform: scale(0.7);
    flex: 0 0 33%;
  }
  @media (max-width: 480px) {
    transform: scale(0.6);
    flex: 0 0 33%;
    margin-top: -100px;
  }
`
const StyledMain = styled(Main)`
  width: 442px;
  height: 644px;
`
const StyledMe = styled(Me)`
  width: 182px;
  height: 205px;
  position: absolute;
  bottom: 44px;
  left: 15%;
`
const StyledDoor = styled(Door)`
  position: absolute;
  bottom: 0%;
  right: -5%;
  width: 550px;
  height: 982px;
  z-index: 0;
  transform-origin: bottom;
  transform: scale(0.9);
  overflow: visible;
  @media (max-width: 1230px) {
    right: 0%;
    transform: scale(0.85);
  }
  @media (max-width: 992px) {
    transform: scale(0.75);
  }
  @media (max-width: 768px) {
    right: -146px;
    transform: scale(0.58);
  }
  @-webkit-keyframes door-open {
    from {
      transform: scaleX(1);
    }
    to {
      transform: scaleX(-0.3);
    }
  }
  @keyframes door-open {
    from {
      transform: scaleX(1);
    }
    to {
      transform: scaleX(-0.3);
    }
  }
  @-webkit-keyframes door-close {
    from {
      transform: scaleX(-0.3);
    }
    to {
      transform: scaleX(1);
    }
  }
  @keyframes door-close {
    from {
      transform: scaleX(-0.3);
    }
    to {
      transform: scaleX(1);
    }
  }
  .doorpanel {
    transform-origin: 489px;
    animation: door-close 1s ease;
  }
  ${BuildingDiv}:hover & {
    .doorpanel {
      animation: door-open 1s ease;
      transform: scaleX(-0.3);
    }
  }
`

const DoorDiv = styled.div`
  position: absolute;
  bottom: 0%;
  right: 10px;
  width: 425px;
  height: 736px;
  z-index: 0;
  transform: scale(0.9);
  transform-origin: bottom;
  background: #7d7d7d;

  @media (max-width: 1230px) {
    right: 62px;
    transform: scale(0.85);
  }
  @media (max-width: 992px) {
    right: 61px;
    transform: scale(0.75);
  }
  @media (max-width: 768px) {
    right: -84px;
    transform: scale(0.58);
  }
  ${BuildingDiv}:hover & {
    background-color: #b7b7b7;
    transition: background-color 1000ms linear;
  }
`

const Building = ({ children }) => (
  <BuildingDiv id="projects">
    <Plants />
    <Title>MY PROJECTS</Title>
    <Flex>
      <Window title={1}>
        <Preview title={1}>
          A set of interactive games using the Philips Hue API <br />
        </Preview>
      </Window>
      <Window title={2}>
        <Preview title={2}>
          A website for STASIS Rehabilitation, a physiotherapy clinic. <br />
        </Preview>
      </Window>
      <Window title={3}>
        <Preview title={3}>
          A static website that presents a custom icon set called Iconoclasm.
          <br />
        </Preview>
      </Window>
      <WindowDiv>
        <StyledMe />
        <StyledMain />
      </WindowDiv>
      <Window title={4}>
        <Preview title={4}>
          A website for a new equipment financing business <br />
        </Preview>
      </Window>
      <Window title={5}></Window>
    </Flex>
    <About id="about" />
    <DoorDiv>
      <Skills />
    </DoorDiv>
    <StyledDoor />
  </BuildingDiv>
)

export default Building

const Title = styled.h2`
  position: absolute;
  left: 0px;
  top: 130px;

  font-size: 28px;
  color: #282b47;
  flex: 0 0 100%;

  @media (max-width: 992px) {
    height: 20px;
    left: 15%;
    top: 80px;
    width: 100%;
  }
  @media (max-width: 768px) {
    height: 20px;
    left: 12%;
    width: 100%;
  }
`
