import React from "react"
import LeafyPlant from "../images/leafyplant.svg"
import styled from "@emotion/styled"

const Plant = styled(LeafyPlant)`
  width: 100%;
  height: auto;
  transform: translateY(1px);
  @-webkit-keyframes grow {
    from {
      transform: scale(0.01);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes grow {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .vine2 {
    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
    @-webkit-keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    -webkit-animation: dash 3.3s linear forwards;
    animation: dash 3.3s linear forwards;
  }
  .vine3 {
    stroke-dasharray: 202;
    stroke-dashoffset: 202;
    @keyframes dash1 {
      to {
        stroke-dashoffset: 404;
      }
    }
    @-webkit-keyframes dash1 {
      to {
        stroke-dashoffset: 404;
      }
    }
    -webkit-animation: dash1 2.7s linear forwards;
    animation: dash1 2.7s linear forwards;
  }
  .vine1 {
    stroke-dasharray: 191;
    stroke-dashoffset: 191;
    @keyframes dash2 {
      to {
        stroke-dashoffset: 382;
      }
    }
    @-webkit-keyframes dash2 {
      to {
        stroke-dashoffset: 382;
      }
    }
    -webkit-animation: dash2 2.7s linear forwards;
    animation: dash2 2.7s linear forwards;
  }
  .leaves > * {
    transform: scale(0);
    transform-box: fill-box;
    transform-origin: center;
    -webkit-transform-box: fill-box;
    -webkit-transform-origin: center;
    -webkit-animation: grow 1s ease forwards;
    animation: grow 1s ease forwards;
  }
  .stems > * {
    transform: scale(0);
    transform-box: fill-box;
    transform-origin: center;
    -webkit-transform-box: fill-box;
    -webkit-transform-origin: center;
    -webkit-animation: grow 1s ease forwards;
    animation: grow 1s ease forwards;
  }
  .leaf1-1,
  .leaf2-1,
  .leaf3-1 {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
  }
  .stem1-1,
  .stem2-1,
  .stem3-1 {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }
  .leaf1-2,
  .leaf2-2,
  .leaf3-2 {
    animation-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
  }
  .stem1-2,
  .stem2-2,
  .stem3-2 {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
  }
  .leaf1-3,
  .leaf2-3,
  .leaf3-3 {
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
  }
  .stem1-3,
  .stem2-3,
  .stem3-3 {
    animation-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
  }
  .leaf1-4,
  .leaf2-4,
  .leaf3-4 {
    animation-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
  }
  .stem1-4,
  .stem2-4,
  .stem3-4 {
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
  }
  .leaf1-5,
  .leaf2-5,
  .leaf3-5 {
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
  }
  .stem1-5,
  .stem2-5,
  .stem3-5 {
    animation-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
  }
  .leaf1-6,
  .leaf2-6,
  .leaf3-6 {
    animation-delay: 2.2s;
    -webkit-animation-delay: 2.2s;
  }
  .stem1-6,
  .stem2-6,
  .stem3-6 {
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
  }
  .leaf1-7,
  .leaf2-7,
  .leaf3-7 {
    animation-delay: 2.4s;
    -webkit-animation-delay: 2.4s;
  }
  .stem1-7,
  .stem2-7,
  .stem3-7 {
    animation-delay: 2.2s;
    -webkit-animation-delay: 2.2s;
  }
  .leaf1-8,
  .leaf2-8,
  .leaf3-8 {
    animation-delay: 2.6s;
    -webkit-animation-delay: 2.6s;
  }
  .stem1-8,
  .stem2-8,
  .stem3-8 {
    animation-delay: 2.4s;
    -webkit-animation-delay: 2.4s;
  }
  .leaf1-9,
  .leaf2-9,
  .leaf3-9 {
    animation-delay: 2.8s;
    -webkit-animation-delay: 2.8s;
  }
  .stem1-9,
  .stem2-9,
  .stem3-9 {
    animation-delay: 2.6s;
    -webkit-animation-delay: 2.6s;
  }
  .leaf1-10,
  .leaf2-10,
  .leaf3-10 {
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
  }
  .stem1-10,
  .stem2-10,
  .stem3-10 {
    animation-delay: 2.8s;
    -webkit-animation-delay: 2.8s;
  }
  .leaf1-11,
  .leaf2-11,
  .leaf3-11 {
    animation-delay: 3.2s;
    -webkit-animation-delay: 3.2s;
  }
  .stem1-11,
  .stem2-11,
  .stem3-11 {
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
  }
  .leaf1-12,
  .leaf2-12,
  .leaf3-12 {
    animation-delay: 3.4s;
    -webkit-animation-delay: 3.4s;
  }
  .stem1-12,
  .stem2-12,
  .stem3-12 {
    animation-delay: 3.2s;
    -webkit-animation-delay: 3.2s;
  }
  .leaf1-13,
  .leaf2-13,
  .leaf3-13 {
    animation-delay: 3.4s;
    -webkit-animation-delay: 3.4s;
  }
  .stem1-13,
  .stem2-13,
  .stem3-13 {
    animation-delay: 3.2s;
    -webkit-animation-delay: 3.2s;
  }
  .leaf2-14 {
    transform-box: fill-box;
    transform-origin: bottom;
    -webkit-transform-box: fill-box;
    -webkit-transform-origin: bottom;
    -webkit-animation: grow 1s ease forwards;
    animation: grow 1s ease forwards;
    animation-delay: 3.5s;
    -webkit-animation-delay: 3.5s;
  }
`

class Animation extends React.Component {
  render() {
    return <Plant />
  }
}

export default Animation
