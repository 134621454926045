import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Icono from "../images/icon-icono.svg"
import Stasis from "../images/icon-stasis.svg"
import Games from "../images/icon-games.svg"
import Vtts from "../images/icon-vtts.svg"
import Tools from "../images/icon-progress.svg"
import { css } from "@emotion/core"

const StyledIcono = styled(Icono)`
  width: 90%;
  height: 90%;
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
`
const StyledStasis = styled(Stasis)`
  width: 100%;
  height: 100%;
`
const StyledGames = styled(Games)`
  width: 100%;
  height: 100%;
  transform: translateY(40px);
  -webkit-transform: translateY(44px);
`
const StyledVtts = styled(Vtts)`
  width: 100%;
  height: 100%;
`
const StyledProgress = styled(Tools)`
  width: 90%;
  height: 90%;
`
const Container = styled.div`
  width: 90%;
  margin-left: 5%;
  height: 100%;
  padding-top: 35%;
  display: flex;
`
const LogoDiv = styled.div`
  flex: 0 0 35%;
  margin-bottom: 20px;
  margin-left: 5%;
`
const TextBox = styled.div`
  flex: 0 0 60%;
  margin-left: 5%;
`
const Text = styled.p`
  width: 90%;
  color: white;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
`
const Continue = styled(Link)`
  text-decoration: none;
  z-index: 7;
  font-weight: 500;
  font-size: 20px;
  :hover {
    text-decoration: underline;
  }
`

const Preview = ({ title, children }) => {
  function iconSelect(title) {
    if (title === 1) {
      return <StyledGames />
    } else if (title === 2) {
      return <StyledStasis />
    } else if (title === 3) {
      return <StyledIcono />
    } else if (title === 4) {
      return <StyledVtts />
    } else if (title === 5) {
      return <StyledProgress />
    }
  }
  function fontSelect(title) {
    if (title === 1) {
      return "white"
    } else if (title === 2) {
      return "white"
    } else if (title === 3) {
      return "black"
    } else if (title === 4) {
      return "white"
    } else if (title === 5) {
      return "white"
    }
  }
  function linkSelect(title) {
    if (title === 1) {
      return "/gameswithhue/"
    } else if (title === 2) {
      return "/stasisrehab/"
    } else if (title === 3) {
      return "/iconoclasm/"
    } else if (title === 4) {
      return "/vtts/"
    } else if (title === 5) {
      return null
    }
  }
  return (
    <Container>
      <LogoDiv>{iconSelect(title)}</LogoDiv>
      <TextBox>
        <Text
          css={css`
            color: ${fontSelect(title)};
          `}
        >
          {children}
        </Text>
        <Continue
          css={css`
            color: ${fontSelect(title)};
          `}
          to={linkSelect(title)}
        >
          VIEW PROJECT
        </Continue>
      </TextBox>
    </Container>
  )
}

export default Preview
