import React from "react"
import styled from "@emotion/styled"

const AboutDiv = styled.div`
  position: absolute;
  width: 44%;

  font-size: 18px;
  color: black;
  z-index: 5;
  bottom: 140px;
  left: 0;
  line-height: 28px;

  @media (max-width: 1200px) {
    left: 5%;
    width: 40%;
  }
  @media (max-width: 992px) {
    width: 85%;
    bottom: 750px;
    left: 17%;
  }
  @media (max-width: 768px) {
    bottom: 720px;
    left: 25%;
    width: 70%;
    font-size: 16px;
  }
  @media (max-width: 480px) {
    bottom: 720px;
    left: 20%;
    width: 85%;
  }
`

const Title = styled.h2`
  font-size: 28px;
  color: #282b47;
`

const About = () => (
  <AboutDiv id="about">
    <Title>ABOUT ME</Title>
    <p>
      <br />
      Growing up, I wanted to be a physiotherapist. I find the mechanics of the
      human body amazing, and I really liked the idea of helping people. When I
      first began coding with basic javascript, I realized the power of coding
      and how it can also improve people’s lives in a different way. So I
      decided to change superpowers and completed the Fullstack Development
      program at Concordia University. Since graduating, I’ve been practicing my
      skills in JS, CSS, React, and nodeJS through contract work and personal
      projects.
      <br />
      <br />
      I was born and raised in Surrey, BC and am currently based in Montreal.
      Chez Claudette has my favourite poutine ever.
      <br />
      <br />
      I’m looking to work in a Development team and continue growing my skills
      and network. So if you want to learn more about my projects, don’t
      hesitate to reach out!
      <br />
    </p>
  </AboutDiv>
)

export default About
