import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const IntroBox = styled.div`
  font-style: normal;
  font-weight: normal;
  color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 700px;
  font-size: 35px;
  line-height: 44px;
`

const Hero = () => (
  <herodiv
    css={css`
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      padding: 20px;
      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
    `}
  >
    <IntroBox>
      <h5>
        Hi there, I’m{" "}
        <span
          css={css`
            color: #44cc2e;
          `}
        >
          Avtar
        </span>
        !
      </h5>
      <div
        css={css`
          font-size: 28px;
        `}
      >
        Full stack gardener, I mean developer.{" "}
      </div>
      <div
        css={css`
          padding-top: 25px;
          font-size: 18px;
          line-height: 25px;
        `}
      >
        What I like most about building websites and apps is similar to what I
        enjoy about gardening— seeing the fruits of my labour come to life!{" "}
      </div>
    </IntroBox>
  </herodiv>
)

export default Hero
