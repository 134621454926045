import React from "react"
import styled from "@emotion/styled"
import BuildingDiv from "../components/building"
import Layout from "../components/layout"
import Building from "../images/building.svg"
import Footer from "../components/footer"
import Header from "../components/header"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"

const Container1 = styled.div`
  width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  @media (max-width: 1200px) {
  }
`
const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 calc((100vw - 1200px) / 2);
`
const StyledBuilding = styled(Building)`
  height: 2866px;

  overflow: visible;
  transform: scaleX(0.9);
  position: absolute;
  @media (max-width: 992px) {
    transform: scaleY(1.1) translate(15%, 13%);
  }
  @media (max-width: 768px) {
    width: 90%;
    transform: scaleY(1.1) translate(5%, 13%);
  }
`

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Avtar G - My Portfolio</title>
      <link rel="canonical" href="https://avtar.dev" />
    </Helmet>
    <Layout>
      <Container1>
        <Header />
        <Hero />
      </Container1>
      <Container>
        <StyledBuilding />
        <BuildingDiv id="projects" />
      </Container>
      <Footer id="contact" />
    </Layout>
  </>
)
