import React from "react"
import styled from "@emotion/styled"
import PlantSocial from "../images/plantsocial.svg"
import PlantTall from "../components/animation"
import PlantShort from "../images/plantshort.svg"
import { keyframes } from "@emotion/core"
const Plants = () => (
  <PlantDiv>
    <Short />
    <Tall />
    <Social />
  </PlantDiv>
)

const PlantDiv = styled.div`
  position: absolute;
  right: 0%;
  top: -327px;
  width: 360px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 8;
  @media (max-width: 1200px) {
    right: 3%;
  }
  @media (max-width: 768px) {
    width: 330px;
    top: -299px;
    right: -50px;
  }
  @media (max-width: 480px) {
    width: 280px;
    top: -255px;
    right: -45px;
  }
`

const wiggle = keyframes`
  from, 20%, 53%, 80%, to {
    transform: rotate(0,0,0) scale(1.2)
  }
  40%, 43% {
    transform: rotate(-4deg) scale(1.2)
  }

  70% {
    transform: rotate(4deg) scale(1.2)
  }

  90% {
    transform: rotate(-4deg) scale(1.2)
  }

`

const Social = styled(PlantSocial)`
  width: 100%;
  transform-origin: center bottom;
  bottom: -5px;
  transform: scale(1.1) translateY(1px);

  .linkedin:hover {
    transform-box: fill-box;
    transform-origin: center top;
    animation: ${wiggle} 1s ease forwards;
    position: relative;
  }
  .mail:hover {
    transform-box: fill-box;
    transform-origin: center top;
    animation: ${wiggle} 1s ease forwards;
    position: relative;
  }
  .twitter:hover {
    transform-box: fill-box;
    transform-origin: center top;
    animation: ${wiggle} 1s ease forwards;
    position: relative;
  }
  .github:hover {
    transform-box: fill-box;
    transform-origin: center top;
    animation: ${wiggle} 1s ease forwards;
    position: relative;
  }
`

const Short = styled(PlantShort)`
  width: 100%;
  bottom: -5px;
  transform: translateY(1px);
`
const Tall = styled(PlantTall)`
 
  width: 100%;
  @media (max-width: 992px) {
    order: 3;
  }
`
export default Plants
