import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import ProjLinks from "../components/projectlinks"
import scrollTo from "gatsby-plugin-smoothscroll"

const NavLink = styled.button`
  all: unset;
  position: relative;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 35px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: #44cc2e;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    z-index: 6;
    margin-top: 20px;
    padding: 20px 0;
    color: black;
    :hover {
      text-decoration: none;
      color: #44cc2e;
    }
  }
`
const ProjLink = styled.button`
  all: unset;
  position: relative;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 35px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: #44cc2e;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    z-index: 6;
    margin-top: 20px;
    padding: 20px 0;
    color: black;
  }
`
const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  margin: 1vw 0;
  @media (max-width: 768px) {
    display: flex;
  }
`
const Dropdown = styled.div`
  display: none;
  width: 200px;
  height: 0px;
  border: 1px solid #44cc2e;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  z-index: 10;
  left: -10%;
  transition: height 1s ease;
  background: rgba(40, 43, 71, 0.95);
  @keyframes rotateY {
    0% {
      transform: rotateY(90deg);
    }
    80% {
      transform: rotateY(-10deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
  ${ProjLink}:hover & {
    display: block;
    animation: rotateY 300ms ease-in-out forwards;
    transform-origin: top center;
    height: auto;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
    position: relative;
    top: 0%;
    width: 100%;
    z-index: 10;
    left: 0%;
    background: none;
    border: none;
  }
`
const Hamburger = styled.div`
  background-color: #c4c4c4;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: white;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }
  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }
  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Navbox = styled.div`
  position: relative;
  width: 500px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10vh;
    background-color: #c5c5c5;
    transition: all 0.3s ease-in;
    top: 70px;
    left: ${props => (props.open ? "-100%" : "0")};
    z-index: 7;
  }
`

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleClick = () => {
    setNavbarOpen(!navbarOpen)
    scrollTo("#about")
  }
  const handleClick2 = () => {
    setNavbarOpen(!navbarOpen)
    scrollTo("#contact")
  }
  return (
    <header
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        width: 100%;
        height: 100px;
        justify-content: center;
        padding: 20px;
      `}
    >
      <nav
        css={css`
          position: relative;
          height: 40px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>

        {navbarOpen ? (
          <Navbox>
            <NavLink activeClassName="current-page">
              My Projects
              <Dropdown>
                <ProjLinks />
              </Dropdown>
            </NavLink>
            <NavLink
              onClick={() => handleClick()}
              activeClassName="current-page"
            >
              About Me
            </NavLink>
            <NavLink
              onClick={() => handleClick2()}
              activeClassName="current-page"
            >
              Contact
            </NavLink>
          </Navbox>
        ) : (
          <Navbox open>
            <ProjLink
              onClick={() => scrollTo("#projects")}
              activeClassName="current-page"
            >
              My Projects
              <Dropdown>
                <ProjLinks />
              </Dropdown>
            </ProjLink>
            <NavLink
              onClick={() => scrollTo("#about")}
              activeClassName="current-page"
            >
              About Me
            </NavLink>
            <NavLink
              onClick={() => scrollTo("#contact")}
              activeClassName="current-page"
            >
              Contact
            </NavLink>
          </Navbox>
        )}
      </nav>
    </header>
  )
}
export default Header
