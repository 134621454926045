import React from "react"
import styled from "@emotion/styled"

const Contact = () => {
  return (
    <Container>
      <Title>Get in touch</Title>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <FlexContainer>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <label>
            Name
            <Input type="text" name="name" id="name" />
          </label>
          <label>
            Email
            <Input type="email" name="email" id="email" />
          </label>
          <label>
            Subject
            <Input type="text" name="subject" id="subject" />
          </label>
          <label>
            Message
            <Input2 name="message" id="message" rows="5" />
            <div>
              <Button type="reset" value="Clear">
                Clear
              </Button>
              <Button type="submit">Send</Button>
            </div>
          </label>
        </FlexContainer>
      </form>
    </Container>
  )
}
export default Contact

const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 44px;
  color: #3fa72e;
`
const Container = styled.div`
  flex: 0 0 40%;
  margin-left: 10%;
  height: auto;
  @media (max-width: 768px) {
    width: 65%;
    margin-left: 0;
    margin-top: 70px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  > label {
    padding: 5px 0px;
  }
  > button {
    margin: 10px 0;
  }
`

const Input2 = styled.textarea`
  width: 100%;
  border-radius: 7px;
  border: none;
  margin-top: 5px;
`
const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  height: 25px;
  border-radius: 7px;
  border: none;
`

const Button = styled.button`
  border: none;
  font-size: 10px;
  border-radius: 7px;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 15px 5px;
  &:nth-child(1) {
    margin-left: 0px;
  }
`
