import React from "react"
import styled from "@emotion/styled"
import Contact from "../components/contact"
import Linkedin from "../images/svglinkedin.svg"
import Mail from "../images/svgmail.svg"
import Twitter from "../images/svgtwitter.svg"
import Github from "../images/svggithub.svg"
import { Link } from "gatsby"
const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  :visited,
  :focus,
  :active {
    text-decoration: none;
  }
`
const StyledLink1 = styled.a`
  color: black;
  text-decoration: none;
  :visited,
  :focus,
  :active {
    text-decoration: none;
  }
`

const FooterDiv = styled.div`
  width: 100%;
  height: auto;
  top: -1px;
  position: relative;
  background: #c4c4c4;
  padding: 2vw calc((100vw - 1200px) / 2);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Footer = () => (
  <FooterDiv id="contact">
    <Contact />
    <Links>
      <StyledLink to="https://www.github.com/avgre">
        <Flex>
          <IconGithub />
          <span>github.com/avgre</span>
        </Flex>
      </StyledLink>
      <StyledLink to="https://www.linkedin.com/in/avgre/">
        <Flex>
          <IconLinkedin />

          <span>linkedin.com/in/avgre/</span>
        </Flex>
      </StyledLink>
      <StyledLink1 href="mailto:avgredev@gmail.com">
        <Flex>
          <IconMail />
          <span>avgredev@gmail.com</span>
        </Flex>
      </StyledLink1>
      <StyledLink to="https://twitter.com/Av_Gre">
        <Flex>
          <IconTwitter />
          <span>@Av_Gre</span>
        </Flex>
      </StyledLink>
    </Links>
  </FooterDiv>
)

export default Footer

const IconLinkedin = styled(Linkedin)`
  margin: 5px;
  padding: 5px;
`
const IconMail = styled(Mail)`
  margin: 5px;
  padding: 5px;
`
const IconTwitter = styled(Twitter)`
  margin: 5px;
  padding: 5px;
`
const IconGithub = styled(Github)`
  margin: 5px;
  padding: 5px;
`
const Links = styled.div`
  flex: 0 0 40%;
  max-width: 300px;
  margin-left: 10%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    width: 60%;
    margin-left: 5%;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`
